/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'send-arrow-up-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M15.854.146a.5.5 0 01.11.54L13.026 8.03A4.5 4.5 0 008 12.5c0 .5 0 1.5-.773.36l-1.59-2.498L.644 7.184l-.002-.001-.41-.261a.5.5 0 01.083-.886l.452-.18.001-.001L15.314.035a.5.5 0 01.54.111M6.637 10.07l7.494-7.494.471-1.178-1.178.471L5.93 9.363l.338.215a.5.5 0 01.154.154z"/><path pid="1" fill-rule="evenodd" d="M12.5 16a3.5 3.5 0 100-7 3.5 3.5 0 000 7m.354-5.354a.5.5 0 00-.722.016l-1.149 1.25a.5.5 0 10.737.676l.28-.305V14a.5.5 0 001 0v-1.793l.396.397a.5.5 0 00.708-.708z"/>',
    },
});
